import { ApiClient } from "../helpers/api-client";

const apiClient = new ApiClient();

const sinav_turleri_listesi = async () => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/api/v1/sinavTurleriniListele`,
    headers: {
      "donem-id": localStorage.getItem("donem_id"),
      jeton: localStorage.getItem("jeton"),
    },
    method: "POST",
  });
};

const sinav_turlerini_getir = async (id) => {
  const response = await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/api/v1/sinavTurunuGetir`,
    headers: {
      "donem-id": localStorage.getItem("donem_id"),
      jeton: localStorage.getItem("jeton"),
    },
    json: {
      sinavTuru: {
        id,
      },
    },
  });
  return response.veriler.sonuc.sinavTuru;
};

// sinavTuruDersleri,
// puanHesaplamalari,
const sinav_turu_kayit = async (ad, kisaAd, aciklama, oncekiOturumTuruId) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/api/v1/sinavTuruOlustur`,
    headers: {
      "donem-id": localStorage.getItem("donem_id"),
      jeton: localStorage.getItem("jeton"),
    },
    json: {
      sinavTuru: {
        ad,
        kisaAd,
        aciklama,
        oncekiOturumTuruId,
      },
    },
  });
};

const sinav_turu_guncelle = async (
  ad,
  kisaAd,
  aciklama,
  sinavTuruDersleri,
  puanHesaplamalari,
  oncekiOturumTuruId,
  id,
  versiyon
) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/api/v1/sinavTurunuGuncelle`,
    headers: {
      "donem-id": localStorage.getItem("donem_id"),
      jeton: localStorage.getItem("jeton"),
    },
    json: {
      sinavTuru: {
        ad,
        kisaAd,
        aciklama,
        sinavTuruDersleri,
        puanHesaplamalari,
        oncekiOturumTuruId,
        id,
        versiyon,
      },
    },
  });
};
const sinav_turu_sil = async (id, versiyon) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/api/v1/sinavTurunuSil`,
    headers: {
      "donem-id": localStorage.getItem("donem_id"),
      jeton: localStorage.getItem("jeton"),
    },
    json: {
      sinavTuru: {
        id,
        versiyon,
      },
    },
  });
};

export {
  sinav_turleri_listesi,
  sinav_turlerini_getir,
  sinav_turu_kayit,
  sinav_turu_guncelle,
  sinav_turu_sil,
};
