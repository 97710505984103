<template>
  <v-speed-dial
    class="fixed-button"
    :bottom="bottom"
    :direction="direction"
    :transition="transition"
  >
    <template v-slot:activator>
      <v-btn v-model="fab" color="blue darken-2" dark fab>
        <v-icon v-if="fab"> mdi-close </v-icon>
        <v-icon v-else> mdi-email-outline </v-icon>
      </v-btn>
    </template>
    <v-btn @click="openWhatsApp" fab dark small color="green">
      <v-icon>mdi-whatsapp</v-icon>
    </v-btn>
    <v-btn @click="openInstagram" fab dark small color="pink">
      <v-icon>mdi-instagram</v-icon>
    </v-btn>
    <v-btn @click="openYoutube" fab dark small color="red">
      <v-icon>mdi-youtube</v-icon>
    </v-btn>
  </v-speed-dial>

  <!-- <slot v-if="footer" name="footer-bar">
          <footer-bar />
        </slot> -->
</template>

<script>
// import FooterBar from "@/components/FooterBar";

export default {
  name: "Layout",
  data() {
    return {
      narBar: true,
      footer: false,
      direction: "left",
      right: true,
      fab: false,
      bottom: true,
      transition: "slide-y-reverse-transition",
    };
  },

  mounted() {},
  methods: {
    openWhatsApp() {
      window.open(
        "https://api.whatsapp.com/send?phone=+905070378909",
        "_blank"
      );
    },
    openYoutube() {
      window.open(
        "https://www.youtube.com/@sayackutuphanesisinavmerkezi",
        "_blank"
      );
    },
    openInstagram() {
      window.open(
        "https://www.instagram.com/sayacsinavmerkezi/?hl=tr",
        "_blank"
      );
    },
  },
  watch: {
    top(val) {
      this.bottom = !val;
    },
    right(val) {
      this.left = !val;
    },
    bottom(val) {
      this.top = !val;
    },
    left(val) {
      this.right = !val;
    },
  },
};
</script>

<style scoped>
.fixed-button {
  position: fixed;
  bottom: 55px;
  right: 40px;
}
.content {
  /* overflow-x: auto; */
  max-height: calc(100vh - 120px);
}
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
</style>
