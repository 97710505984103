import { ApiClient } from "../helpers/api-client";

const apiClient = new ApiClient();

const sinavlarimi_listele = async () => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/api/v1/sinavlarimiListele`,
    headers: {
      "donem-id": localStorage.getItem("donem_id"),
      jeton: localStorage.getItem("jeton"),
    },
    method: "POST",
  });
};

const karneIndirme = async (sinavId, ogrenciId) => {
  const response = await apiClient.makeRequest({
    url: `${
      process.env.VUE_APP_API_URL
    }/api/v1/sinavKarnem/${localStorage.getItem(
      "donem_id"
    )}/${sinavId}/${ogrenciId}`,
    headers: {
      "donem-id": localStorage.getItem("donem_id"),
      jeton: localStorage.getItem("jeton"),
    },
    method: "POST",
  });
  return response;
};

const ogrencinin_net_sayilarini_getir = async (id) => {
  const response = await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/api/v1/ogrencininNetSayilariniGetir`,
    headers: {
      "donem-id": localStorage.getItem("donem_id"),
      jeton: localStorage.getItem("jeton"),
    },
    json: {
      ogrenci: {
        id,
      },
    },
  });
  return response.veriler;
};

const sinav_bilgileri = async (id) => {
  return await apiClient.makeRequest({
    url: `${
      process.env.VUE_APP_API_URL
    }/api/v1/belge/sinavBilgileri/${localStorage.getItem("donem_id")}/${id}`,
    headers: {
      "donem-id": localStorage.getItem("donem_id"),
      jeton: localStorage.getItem("jeton"),
    },
  });
};

export {
  sinavlarimi_listele,
  karneIndirme,
  ogrencinin_net_sayilarini_getir,
  sinav_bilgileri,
};
