var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', {
    staticClass: "mt-8 mx-auto"
  }, [_c('v-sheet', {
    staticClass: "v-sheet--offset mx-auto",
    attrs: {
      "color": "cyan",
      "elevation": "12",
      "max-width": "calc(100% - 32px)"
    }
  }, [_c('v-sparkline', {
    attrs: {
      "labels": _vm.labels,
      "show-labels": true,
      "value": _vm.value,
      "gradient": _vm.gradient,
      "smooth": _vm.radius || false,
      "padding": _vm.padding,
      "line-width": _vm.width,
      "stroke-linecap": _vm.lineCap,
      "gradient-direction": _vm.gradientDirection,
      "fill": _vm.fill,
      "type": _vm.type,
      "auto-line-width": _vm.autoLineWidth,
      "auto-draw": ""
    }
  })], 1), _c('v-card-text', {
    staticClass: "pt-0"
  }, [_c('div', {
    staticClass: "text-h6 font-weight-light mb-2"
  }, [_vm._v(_vm._s(_vm.title) + " Netleri")]), _vm._v(" Toplam girilen sınav sayısı "), _c('strong', [_vm._v(_vm._s(_vm.gelen_data.length))]), _c('v-divider', {
    staticClass: "my-2"
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }