import Vue from "vue";
import VueRouter from "vue-router";
import AnaSayfa from "../views/AnaSayfa.vue";
import { yetki } from "../helpers/yetkiler";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Root",
    meta: { yetkiler: [""] },
    redirect: "/AnaSayfa",
  },
  {
    path: "/AnaSayfa",
    name: "AnaSayfa",
    component: AnaSayfa,
  },
  {
    path: "/KimliklerList",
    name: "KimliklerList",
    meta: { yetkiler: ["kimlikleriListele"] },
    component: () => import("../views/Kimlikler/List.vue"),
  },
  {
    path: "/OnkayitList",
    name: "OnkayitList",
    meta: { yetkiler: ["onkayitlariListele"] },
    component: () => import("../views/OnKayit/List.vue"),
  },
  {
    path: "/VelilerList",
    name: "VelilerList",
    meta: { yetkiler: ["velileriListele"] },
    component: () => import("../views/Veliler/List.vue"),
  },
  {
    path: "/OgrencilerList",
    name: "OgrencilerList",
    meta: { yetkiler: ["ogrencileriListele"] },
    component: () => import("../views/Ogrenciler/List.vue"),
  },
  {
    path: "/PersonelList",
    name: "PersonelList",
    meta: { yetkiler: ["personelleriListele"] },
    component: () => import("../views/Personeller/List.vue"),
  },
  {
    path: "/SinifList",
    name: "SinifList",
    meta: { yetkiler: ["siniflariListele"] },
    component: () => import("../views/GenelTanimlar/Siniflar/List.vue"),
  },
  {
    path: "/DersList",
    name: "DersList",
    meta: { yetkiler: ["dersleriListele"] },
    component: () => import("../views/GenelTanimlar/Dersler/List.vue"),
  },
  {
    path: "/DersBolumList",
    name: "DersBolumList",
    meta: { yetkiler: ["dersBolumleriniListele"] },
    component: () => import("../views/GenelTanimlar/Bolumler/List.vue"),
  },
  {
    path: "/UniteList",
    name: "UniteList",
    meta: { yetkiler: ["dersUniteleriniListele"] },
    component: () => import("../views/GenelTanimlar/Uniteler/List.vue"),
  },
  {
    path: "/AlanlarList",
    name: "AlanlarList",
    meta: { yetkiler: ["alanlariListele"] },
    component: () => import("../views/GenelTanimlar/Alanlar/List.vue"),
  },
  {
    path: "/KonuList",
    name: "KonuList",
    meta: { yetkiler: ["dersKonulariniListele"] },
    component: () => import("../views/GenelTanimlar/Konular/List.vue"),
  },
  {
    path: "/SeviyeList",
    name: "SeviyeList",
    meta: { yetkiler: ["sinifSeviyeleriniListele"] },
    component: () => import("../views/GenelTanimlar/Seviyeler/List.vue"),
  },
  {
    path: "/OkulList",
    name: "OkulList",
    meta: { yetkiler: ["okullariListele"] },
    component: () => import("../views/GenelTanimlar/Okullar/List.vue"),
  },
  {
    path: "/OlcSeviyeList",
    name: "OlcSeviyeList",
    meta: { yetkiler: ["sinavSeviyeleriniListele"] },
    component: () =>
      import("../views/OlcmeDegerlendirme/Tanimlar/Seviyeler/List.vue"),
  },
  {
    path: "/OlcSinavMerkezleriList",
    name: "OlcSinavMerkezleriList",
    meta: { yetkiler: ["sinavSeviyeleriniListele"] },
    component: () =>
      import("../views/OlcmeDegerlendirme/Tanimlar/SinavMerkezleri/List.vue"),
  },
  {
    path: "/OptikFormList",
    name: "OptikFormList",
    meta: { yetkiler: ["optikFormlariListele"] },
    component: () =>
      import("../views/OlcmeDegerlendirme/OptikFormlar/List.vue"),
  },
  {
    path: "/SinavTurleriList",
    name: "SinavTurleriList",
    meta: { yetkiler: ["sinavTurleriniListele"] },
    component: () =>
      import("../views/OlcmeDegerlendirme/Tanimlar/SinavTurleri/List.vue"),
  },
  {
    path: "/SinavlarimList",
    name: "SinavlarimList",
    meta: { yetkiler: ["sinavlarimiListele"] },
    component: () =>
      import("../views/OlcmeDegerlendirme/OgrenciPaneli/List.vue"),
  },
  {
    path: "/Istatistik",
    name: "Istatistik",
    meta: { yetkiler: ["ogrencininNetSayilariniGetir"] },
    component: () => import("../views/OlcmeDegerlendirme/Istatistik/List.vue"),
    props: (route) => ({
      ogrenci_id: route.params.ogrenci_id,
    }),
  },
  {
    path: "/SinavList",
    name: "SinavList",
    meta: { yetkiler: ["sinavlariListele"] },
    component: () => import("../views/OlcmeDegerlendirme/Sinavlar/List.vue"),
  },

  {
    path: "/test",
    name: "test",
    component: () => import("../views/OlcmeDegerlendirme/test.vue"),
  },
  {
    path: "/SinifEtiketleriList",
    name: "SinifEtiketleriList",
    meta: { yetkiler: ["sinifEtiketleriniListele"] },
    component: () => import("../views/Etiketler/Sınıf/List.vue"),
  },
  {
    path: "/OgrenciEtiketleriList",
    name: "OgrenciEtiketleriList",
    meta: { yetkiler: ["ogrenciEtiketleriniListele"] },
    component: () => import("../views/Etiketler/Ogrenci/List.vue"),
  },
  {
    path: "/RollerList",
    name: "RollerList",
    meta: { yetkiler: ["rolleriListele"] },
    component: () => import("../views/Kullanicilar/Roller/List.vue"),
  },
  {
    path: "/YetkilerList",
    name: "YetkilerList",
    meta: { yetkiler: ["yetkileriListele"] },
    component: () => import("../views/Kullanicilar/Yetkiler/List.vue"),
  },
  {
    path: "/KullanicilarList",
    name: "KullanicilarList",
    meta: { yetkiler: ["kullanicilariListele"] },
    component: () => import("../views/Kullanicilar/Kullanici/List.vue"),
  },
  {
    path: "/SubelerList",
    name: "SubelerList",
    meta: { yetkiler: ["subeleriListele"] },
    component: () => import("../views/SistemAyarlari/Subeler/List.vue"),
  },
  {
    path: "/DonemlerList",
    name: "DonemlerList",
    meta: { yetkiler: ["donemleriListele"] },
    component: () => import("../views/SistemAyarlari/Donemler/List.vue"),
  },
  {
    path: "/KurumlarList",
    name: "KurumlarList",
    meta: { yetkiler: ["kurumlariListele"] },
    component: () => import("../views/SistemAyarlari/Kurumlar/List.vue"),
  },
  {
    path: "/Gizlilik",
    name: "Gizlilik",
    component: () => import("../views/Kvkk/Gizlilik.vue"),
  },
  {
    path: "/Destek",
    name: "Destek",
    component: () => import("../views/Kvkk/Destek.vue"),
  },
  {
    path: "/OgrencilerimList",
    name: "OgrencilerimList",
    meta: { yetkiler: ["ogrencilerimiListele"] },
    component: () =>
      import("../views/OlcmeDegerlendirme/Ogrencilerim/List.vue"),
  },
  {
    path: "/OturumAc",
    name: "OturumAc",
    component: () => import("../views/Login.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (yetki.enAzBiriVarMi(to.meta.yetkiler) === undefined) {
    // console.error("meta etiketi tanımlayınız");
    // return;
  }
  if (yetki.enAzBiriVarMi(to.meta.yetkiler) === false) {
    next({ name: "AnaSayfa" });
  } else {
    const jeton = localStorage.getItem("jeton") ? true : false;
    const sube_id = localStorage.getItem("sube_id") ? true : false;
    const donem_id = localStorage.getItem("donem_id") ? true : false;
    if (to.name !== "OturumAc" && (!jeton || !sube_id || !donem_id)) {
      if (to.name === "Gizlilik") {
        return next();
      } else {
        next({ name: "OturumAc" });
      }
    } else {
      return next();
    }
  }
}); 

export default router;
