<template>
  <v-app>
    <template>
      <slot v-if="narBar" name="nav-bar">
        <nav-bar />
      </slot>

      <v-main>
        <slot name="app-bar">
          <app-bar />
        </slot>
        <slot name="main" />
        <!-- <v-fab-transition>
          <v-btn
            color="green"
            @click="openWhatsApp"
            fab
            large
            dark
            class="fixed-button"
          >
            <v-icon>mdi-whatsapp</v-icon>
          </v-btn>
        </v-fab-transition> -->

        <slot name="menu-button">
          <menu-button></menu-button>
        </slot>
      </v-main>
      <slot v-if="footer" name="footer-bar">
        <footer-bar />
      </slot>
      --
    </template>
  </v-app>
</template>

<script>
import AppBar from "@/components/AppBar";
import NavBar from "@/components/NavBar";
import MenuButton from "@/components/MenuButton";
import FooterBar from "@/components/FooterBar";

export default {
  name: "Layout",
  data() {
    return {
      narBar: true,
      footer: false,
      direction: "left",
      right: true,
      fab: false,
      bottom: true,
      transition: "slide-y-reverse-transition",
    };
  },

  components: {
    AppBar,
    NavBar,
    MenuButton,
    FooterBar,
  },
  mounted() {
    if (
      JSON.parse(localStorage.getItem("roller"))?.includes("ÖĞRENCİ") ||
      JSON.parse(localStorage.getItem("roller"))?.includes("ÖĞRETMEN")
    ) {
      this.narBar = false;
      this.footer = true;
    }
  },
  methods: {
    openWhatsApp() {
      window.open(
        "https://api.whatsapp.com/send?phone=+905070378909",
        "_blank"
      );
    },
    openYoutube() {
      window.open(
        "https://www.youtube.com/@sayackutuphanesisinavmerkezi",
        "_blank"
      );
    },
    openInstagram() {
      window.open(
        "https://www.instagram.com/sayacsinavmerkezi/?hl=tr",
        "_blank"
      );
    },
  },
  watch: {
    top(val) {
      this.bottom = !val;
    },
    right(val) {
      this.left = !val;
    },
    bottom(val) {
      this.top = !val;
    },
    left(val) {
      this.right = !val;
    },
  },
};
</script>

<style scoped>
.fixed-button {
  position: fixed;
  bottom: 60px;
  right: 40px;
}
.content {
  /* overflow-x: auto; */
  max-height: calc(100vh - 120px);
}
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
</style>
