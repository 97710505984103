var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "500"
    },
    model: {
      value: _vm.internalDialog,
      callback: function ($$v) {
        _vm.internalDialog = $$v;
      },
      expression: "internalDialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "justify-center"
  }, [_vm.beklet ? _c('v-progress-circular', {
    attrs: {
      "size": 70,
      "width": 7,
      "color": "blue",
      "indeterminate": ""
    }
  }) : _vm._e(), _vm.beklet === false ? _c('v-img', {
    attrs: {
      "max-width": "100",
      "src": `./gizmo/${_vm.iconlar[_vm.internalIcon]}`
    }
  }) : _vm._e()], 1), _c('v-card-title', {
    staticClass: "justify-center display-1"
  }, [_vm._v(" " + _vm._s(_vm.baslik) + " ")]), _c('v-card-text', [_vm.liste ? _vm._l(_vm.internalAciklama.split('\n'), function (satir) {
    return _c('div', {
      key: satir,
      staticClass: "ml-13 subtitle-2"
    }, [_vm._v(" " + _vm._s(satir) + " ")]);
  }) : [_c('div', {
    staticClass: "text-center title"
  }, [_vm._v(" " + _vm._s(_vm.internalAciklama) + " ")])]], 2), _vm.beklet === false ? _c('v-divider') : _vm._e(), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_vm.evet ? _c('v-btn', {
    staticClass: "my-3",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.tamam
    }
  }, [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]) : _vm._e(), _vm.hayir ? _c('v-btn', {
    attrs: {
      "color": "red",
      "dark": ""
    },
    on: {
      "click": _vm.vazgec
    }
  }, [_vm._v(" Hayır ")]) : _vm._e()], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }