<template>
  <v-footer color="ma-1 primary lighten-1" app fixed padless>
    <BirlestirilmisKonuAnalizSecenegi
      :modal="birlestirilmisKonuAnaliziTetikle"
      @hideDialog="(i, s) => BirlestirilmisKonuShowDialog(i, s)"
    ></BirlestirilmisKonuAnalizSecenegi>
    <v-row justify="center" no-gutters>
      <v-btn
        v-for="(icon, index) in icons"
        :key="icon"
        :to="to[index]"
        color="white"
        text
        rounded
        class="my-2"
        @click="analizIndir(text[index])"
      >
        <div class="d-flex flex-column align-center">
          <v-icon size="20px">{{ icon }}</v-icon>
          <span class="mt-1 text-caption text-center">{{ text[index] }}</span>
        </div>
      </v-btn>
    </v-row>
  </v-footer>
</template>
<script>
import BirlestirilmisKonuAnalizSecenegi from "../views/OlcmeDegerlendirme/Filtreler/BirlestirilmisKonuAnalizSecenegi.vue";
export default {
  components: {
    BirlestirilmisKonuAnalizSecenegi,
  },
  data: () => ({
    birlestirilmisKonuAnaliziTetikle: null,
    olusan_filtre: null,
    icons: ["mdi-home", "mdi-ab-testing", "mdi-chart-line", "mdi-text-long"],
    text: ["Menü", "Sınavlar", "İstatistik", "B.Karne"],
    to: ["AnaSayfa", "SinavlarimList", "Istatistik"],
  }),

  mounted() {
    if (JSON.parse(localStorage.getItem("roller"))?.includes("ÖĞRETMEN")) {
      this.icons = ["mdi-home"];
      this.text = ["Menü"];
      this.to = ["OgrencilerimList"];
    }
  },

  methods: {
    async analizIndir(text) {
      if (
        JSON.parse(localStorage.getItem("roller"))?.includes("ÖĞRENCİ") &&
        text === "B.Karne"
      ) {
        this.birlestirilmisKonuAnaliziTetikle = true;
      }
    },
    async BirlestirilmisKonuShowDialog(
      secilen_sinav_sayisi,
      secilen_sinav_turu
    ) {
      if (secilen_sinav_sayisi === 5) {
        this.olusan_filtre = "sonBes";
      } else if (secilen_sinav_sayisi === 10) {
        this.olusan_filtre = "sonOn";
      } else {
        this.birlestirilmisKonuAnaliziTetikle = false;
        return;
      }
      const donem_id = localStorage.getItem("donem_id");
      const anchor = document.createElement("a");

      anchor.href = `${
        process.env.VUE_APP_API_URL
      }/api/v1/sinavKonulariBasarimim?jeton=${localStorage.getItem(
        "jeton"
      )}&donemId=${donem_id}&filtre=${
        this.olusan_filtre
      }&sinavTuruId=${secilen_sinav_turu}`;

      anchor.setAttribute("download", "analiz.pdf");
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      this.birlestirilmisKonuAnaliziTetikle = false;
    },
  },
};
</script>
