import { ApiClient } from "../helpers/api-client";

const apiClient = new ApiClient();

const oturumAc = async (kullanici_adi, parola) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/api/v1/oturumAc`,
    json: {
      kullaniciAdi: kullanici_adi,
      parola: parola,
    },
  });
};

const oturumuSonlandir = async () => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/api/v1/oturumuSonlandir`,
    method: "POST",
    headers: {
      "donem-id": localStorage.getItem("donem_id"),
      jeton: localStorage.getItem("jeton"),
    },
  });
};

const test = async () => {
  const response = await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/api/v1/belge/bc8698cc-d15e-474a-8e77-7aea679c31ff/00000000-0000-0000-0000-000000000000`,
    headers: {
      "donem-id": localStorage.getItem("donem_id"),
      jeton: localStorage.getItem("jeton"),
    },
  });
  return response;
};

export { oturumAc, oturumuSonlandir, test };
