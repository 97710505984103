<template>
  <v-app-bar app color="primary" dark>
    <SubeDonem
      :modal="sube_donem_tetikle"
      @DialogKapat="SubeDonemDialogKapat()"
    />

    <v-app-bar-nav-icon
      @click="$store.state.navDrawer = !$store.state.navDrawer"
    ></v-app-bar-nav-icon>
    <div class="d-flex align-center" @click="anasayfa" style="cursor: pointer">
      <v-img
        class="shrink mr-0 ml-2"
        contain
        src="@/assets/netkod.png"
        transition="scale-transition"
        width="45"
        height="100"
      />
    </div>
    <v-toolbar-title>
      <div
        v-if="$vuetify.breakpoint.xsOnly === false"
        class="ml-5 white--text text--darken-2 font-weight-medium"
      >
        {{ secilen_sube_adi }}
        {{ secilen_donem_adi }}
      </div>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-btn @click="donemDegistir()" icon>
      <v-icon>mdi-calendar-clock</v-icon>
    </v-btn>

    <v-menu left bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          @click="
            () => {
              oturumuSonlandir();
            }
          "
        >
          <v-list-item-title>Çıkış</v-list-item-title>
        </v-list-item>

        <!-- <v-list-item>
          <a
            href="https://sayacsinavmerkeziapi.sinifbaskanim.com/api/v1/belge/bc8698cc-d15e-474a-8e77-7aea679c31ff/00000000-0000-0000-0000-000000000000"
            download="test.pdf"
            >Test PDF</a
          >
        </v-list-item> -->
      </v-list>
    </v-menu>
    <!-- <v-spacer></v-spacer>
    <v-btn target="_blank" text @click="donemDegistir()">
      <span class="mr-2">Dönem Değiştir</span>
      <v-icon>mdi-calendar-sync</v-icon>
    </v-btn>
    <v-btn target="_blank" text ">
      <span class="mr-2">Çıkış</span>
      <v-icon>mdi-open-in-new</v-icon>
    </v-btn> -->
  </v-app-bar>
</template>

<script>
import SubeDonem from "../views/SubeDonem.vue";
import { oturumuSonlandir, test } from "../query/oturum";
import { silv2 } from "@/query/kullanicilar";
export default {
  methods: {
    async oturumuSonlandir() {
      const sonuc = await oturumuSonlandir();
      if (
        sonuc?.veriler?.kod === "oturum_sonlandirma_basarili" ||
        sonuc?.veriler?.kod === "oturum_sonlandirilmis"
      ) {
        this.$router.push({
          name: "OturumAc",
        });
        localStorage.clear();
      } else {
        this.$gizmo().ac({
          aciklama: "Oturum Sonlandırılamadı",
          baslik: "Dikkat",
          hayir: false,
        });
      }
    },
    donemDegistir() {
      this.sube_donem_tetikle = true;
    },
    async guncelle() {
      const sonuc = await silv2(
        localStorage.getItem("kullanici_id"),
        localStorage.getItem("kullanici_versiyon")
      );
      if (sonuc.veriler.kod !== "basarili_yanit") {
        this.hatali(sonuc.veriler.mesaj);
      } else {
        this.oturumuSonlandir();
      }
    },
    SubeDonemDialogKapat() {
      this.secilen_sube_adi = localStorage.getItem("sube_adi");
      this.secilen_donem_adi = localStorage.getItem("donem_adi");
      this.sube_donem_tetikle = false;
    },
    anasayfa() {
      if (this.$route.path !== "/AnaSayfa") {
        this.$router.push({ name: "AnaSayfa" });
      }
    },
    hesapSil() {
      this.guncelle();
    },
    async testtest() {
      const blob = await test();

      const tempURL = URL.createObjectURL(blob);

      const anchor = document.createElement("a");
      anchor.href = tempURL;
      anchor.download = "test.pdf";

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      URL.revokeObjectURL(tempURL);
    },
    anchor() {
      var link = document.createElement("a");
      link.href =
        "https://sayacsinavmerkeziapi.sinifbaskanim.com/api/v1/belge/bc8698cc-d15e-474a-8e77-7aea679c31ff/00000000-0000-0000-0000-000000000000"; // İndirilecek dosyanın URL'si

      link.setAttribute("download", ""); // Dosya doğrudan indirilsin

      // Gizli anchor'ı body içine ekleyin
      document.body.appendChild(link);

      // Anchor'a tıklama işlemini simüle edin
      link.click();

      // Artık gizli anchor'ı kaldırabilirsiniz
      document.body.removeChild(link);
    },
  },
  components: { SubeDonem },
  data() {
    return {
      sube_donem_tetikle: false,
      secilen_sube_adi: localStorage.getItem("sube_adi"),
      secilen_donem_adi: localStorage.getItem("donem_adi"),
    };
  },
  computed: {
    sube_donem_basligi() {
      return this.$vuetify.breakpoint.xsOnly
        ? "ml-3 subtitle-1"
        : "ml-5 white--text text--darken-2 font-weight-medium";
    },
  },
};
</script>

<style></style>
