<template>
  <v-navigation-drawer
    v-model="$store.state.navDrawer"
    :mini-variant.sync="$store.state.navDrawer"
    permanent
    app
  >
    <v-list>
      <v-list-item class="justify-center">
        <v-img
          :max-height="$store.state.navDrawer ? 50 : 80"
          :max-width="$store.state.navDrawer ? 50 : 80"
          src="@/assets/netkod.png"
        ></v-img>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title v-if="!$store.state.navDrawer" class="text-h6">{{
            kullanici_adi
          }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon>mdi-menu-down</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list nav dense>
      <div
        v-for="(
          { listGroup, prependIcon, items, value, title, icon, to, yetki }, i
        ) in list"
        :key="i"
      >
        <div v-if="listGroup">
          <v-list-group
            v-if="yetki"
            :value="value"
            :prepend-icon="prependIcon"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-title>{{ title }}</v-list-item-title>
            </template>
            <div
              v-for="(
                { subGroup, subItems, value, title, icon, to, yetki }, i
              ) in items"
              :key="i"
            >
              <div v-if="subGroup">
                <v-list-group v-if="yetki" :value="value" no-action sub-group>
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>{{ title }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item
                    :to="to"
                    v-for="({ title, icon, to }, i) in subItems.filter(
                      (v) => v.yetki === yetki
                    )"
                    :key="i"
                    link
                  >
                    <v-list-item-title>{{ title }}</v-list-item-title>
                    <v-list-item-icon>
                      <v-icon> {{ icon }}</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-group>
              </div>
              <div v-else>
                <v-list-item v-if="yetki" :to="to" :key="i" class="pl-64">
                  <v-list-item-title>{{ title }}</v-list-item-title>
                  <v-list-item-icon>
                    <v-icon> {{ icon }}</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </div>
            </div>
          </v-list-group>
        </div>
        <div v-else>
          <v-list-item v-if="yetki" :to="to">
            <v-list-item-icon>
              <v-icon> {{ icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ title }}</v-list-item-title>
          </v-list-item>
        </div>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  beforeMount() {
    this.kullanici_adi = localStorage.getItem("kullanici_adi");
    this.list
      .filter((l) => l.listGroup)
      .forEach((g1) => {
        if (
          g1.items.find(
            (i) =>
              i.to &&
              (i.to.name === this.$route.name ||
                i.relatedRoutes?.includes(this.$route.name))
          )
        ) {
          Object.defineProperty(g1, "value", { value: true });
        }
        g1.items
          .filter((i) => i.subGroup)
          .forEach((g2) => {
            if (
              g2.subItems.find(
                (i) =>
                  i.to &&
                  (i.to.name === this.$route.name ||
                    i.relatedRoutes?.includes(this.$route.name))
              )
            ) {
              Object.defineProperty(g1, "value", { value: true });
              Object.defineProperty(g2, "value", { value: true });
            }
          });
      });
  },
  created() {
    // JSON.parse(localStorage.getItem("roller")).includes("ÖĞRENCİ"),
    this.list = [
      {
        title: "Ana Sayfa",
        icon: "mdi-home",
        to: { name: "AnaSayfa" },
        yetki: !JSON.parse(localStorage.getItem("roller"))?.includes("ÖĞRENCİ"),
      },
      {
        title: "Ön Kayıt",
        icon: "mdi-file-document-edit",
        to: { name: "OnkayitList" },
        yetki: this.$yetki.varMi("onkayitlariListele"),
      },
      {
        listGroup: true,
        prependIcon: "mdi-card-account-details",
        title: "Öğrenci işlemleri",
        yetki: this.$yetki.enAzBiriVarMi([
          "ogrencileriListele",
          "velileriListele",
        ]),
        items: [
          {
            title: "Öğrenciler",
            icon: "mdi-account-school",
            to: { name: "OgrencilerList" },
            yetki: this.$yetki.varMi("ogrencileriListele"),
          },
          {
            title: "Veliler",
            icon: "mdi-human-male-female-child",
            to: { name: "VelilerList" },
            yetki: this.$yetki.varMi("velileriListele"),
          },
        ],
      },
      {
        listGroup: true,
        prependIcon: "mdi-ruler-square-compass",
        title: "Ölçme Değerlendirme",
        yetki: this.$yetki.enAzBiriVarMi([
          "sinavlariListele",
          "sinavlarimiListele",
          "optikFormlariListele",
          "sinavTurleriniListele",
          "sinavSeviyeleriniListele",
          "dersBolumleriniListele",
          "dersleriListele",
          "ogrencilerimiListele",
        ]),
        items: [
          {
            title: "Sınavlar",
            icon: "mdi-ab-testing",
            to: { name: "SinavList" },
            yetki: this.$yetki.varMi("sinavlariListele"),
          },
          {
            title: "Sınavlarım",
            icon: "mdi-ab-testing",
            to: { name: "SinavlarimList" },
            yetki: this.$yetki.varMi("sinavlarimiListele"),
          },
          {
            title: "Öğrencilerim",
            icon: "mdi-ab-testing",
            to: { name: "OgrencilerimList" },
            yetki: this.$yetki.varMi("ogrencilerimiListele"),
          },
          {
            title: "Optik Formlar",
            icon: "mdi-form-textbox-password",
            to: { name: "OptikFormList" },
            yetki: this.$yetki.varMi("optikFormlariListele"),
          },
          {
            subGroup: true,
            title: "Tanımlar",
            yetki: this.$yetki.enAzBiriVarMi([
              "sinavTurleriniListele",
              "sinavSeviyeleriniListele",
              "dersBolumleriniListele",
              "dersleriListele",
            ]),
            subItems: [
              {
                title: "Sınav Türleri",
                icon: "mdi-format-list-bulleted",
                to: { name: "SinavTurleriList" },
                yetki: this.$yetki.varMi("sinavTurleriniListele"),
              },
              {
                title: "Seviyeler",
                icon: "mdi-numeric",
                to: { name: "OlcSeviyeList" },
                yetki: this.$yetki.varMi("sinavSeviyeleriniListele"),
              },
              {
                title: "Sınav Merkezleri",
                icon: "mdi-town-hall",
                to: { name: "OlcSinavMerkezleriList" },
                yetki: this.$yetki.varMi("sinavMerkezleriniListele"),
              },
            ],
          },
        ],
      },
      // {
      //   listGroup: true,
      //   prependIcon: "mdi-message-processing-outline",
      //   title: "Sms İşlemleri",
      //   yetki: this.$yetki.enAzBiriVarMi(["sinavlariListele"]),
      //   items: [
      //     {
      //       title: "Serbest Sms",
      //       icon: "mdi-message-text-fast",
      //       to: { name: "SinavList" },
      //       yetki: this.$yetki.varMi("sinavlariListele"),
      //     },
      //     {
      //       subGroup: true,
      //       title: "Tanımlar",
      //       yetki: this.$yetki.enAzBiriVarMi(["sinavTurleriniListele"]),
      //       subItems: [
      //         {
      //           title: "Sms Tanımları",
      //           icon: "mdi-format-list-bulleted",
      //           to: { name: "SinavTurleriList" },
      //           yetki: this.$yetki.varMi("sinavTurleriniListele"),
      //         },
      //       ],
      //     },
      //   ],
      // },

      {
        listGroup: true,
        prependIcon: "mdi-file-cog-outline",
        title: "Genel Tanımlar",
        yetki: this.$yetki.enAzBiriVarMi([
          "sinifSeviyeleriniListele ",
          "siniflariListele",
          "alanlariListele",
          "dersBolumleriniListele",
          "dersleriListele",
          "sinifEtiketleriniListele",
          "ogrenciEtiketleriniListele",
          "dersUniteleriniListele",
          "dersKonulariniListele",
        ]),
        items: [
          {
            title: "Seviyeler",
            icon: "mdi-numeric",
            to: { name: "SeviyeList" },
            yetki: this.$yetki.varMi("sinifSeviyeleriniListele"),
          },
          {
            title: "Okullar",
            icon: "mdi-town-hall",
            to: { name: "OkulList" },
            yetki: this.$yetki.varMi("sinifSeviyeleriniListele"),
          },
          {
            subGroup: true,
            title: "Sınıflar  ",
            yetki: this.$yetki.enAzBiriVarMi([
              "siniflariListele",
              "alanlariListele",
            ]),
            subItems: [
              {
                title: "Sınıflar",
                icon: "mdi-google-classroom",
                to: { name: "SinifList" },
                yetki: this.$yetki.varMi("siniflariListele"),
              },
              {
                title: "Alanlar",
                icon: "mdi-source-branch",
                to: { name: "AlanlarList" },
                yetki: this.$yetki.varMi("alanlariListele"),
              },
            ],
          },

          {
            subGroup: true,
            title: "Dersler",
            yetki: this.$yetki.enAzBiriVarMi([
              "dersBolumleriniListele",
              "dersleriListele",
            ]),
            subItems: [
              {
                title: "Bölümler",
                icon: "mdi-notebook-edit",
                to: { name: "DersBolumList" },
                yetki: this.$yetki.varMi("dersBolumleriniListele"),
              },
              {
                title: "Dersler",
                icon: "mdi-bookshelf",
                to: { name: "DersList" },
                yetki: this.$yetki.varMi("dersleriListele"),
              },
              {
                title: "Üniteler",
                icon: "mdi-periodic-table",
                to: { name: "UniteList" },
                yetki: this.$yetki.varMi("dersUniteleriniListele"),
              },
              {
                title: "Konular",
                icon: "mdi-format-list-group",
                to: { name: "KonuList" },
                yetki: this.$yetki.varMi("dersKonulariniListele"),
              },
            ],
          },
          {
            subGroup: true,
            title: "Etiketler",
            yetki: this.$yetki.enAzBiriVarMi([
              "sinifEtiketleriniListele",
              "ogrenciEtiketleriniListele",
            ]),
            subItems: [
              {
                title: "Sınıflar",
                icon: "mdi-tag-outline",
                to: { name: "SinifEtiketleriList" },
                yetki: this.$yetki.varMi("sinifEtiketleriniListele"),
              },
              {
                title: "Öğrenciler",
                icon: "mdi-tag-outline",
                to: { name: "OgrenciEtiketleriList" },
                yetki: this.$yetki.varMi("ogrenciEtiketleriniListele"),
              },
            ],
          },
        ],
      },
      {
        listGroup: true,
        prependIcon: "mdi-account-switch",
        title: "Kullanıcı Ayarları",
        yetki: this.$yetki.enAzBiriVarMi([
          "rolleriListele ",
          "yetkileriListele",
          "kullanicilariListele",
        ]),
        items: [
          {
            title: "Roller",
            icon: "mdi-electron-framework",
            to: { name: "RollerList" },
            yetki: this.$yetki.varMi("rolleriListele"),
          },
          {
            title: "Yetkiler",
            icon: "mdi-lock-open-check-outline",
            to: { name: "YetkilerList" },
            yetki: this.$yetki.varMi("yetkileriListele"),
          },
          {
            title: "Kullanıcılar",
            icon: "mdi-account-multiple-plus-outline",
            to: { name: "KullanicilarList" },
            yetki: this.$yetki.varMi("kullanicilariListele"),
          },
        ],
      },
      {
        listGroup: true,
        prependIcon: "mdi-cog",
        title: "Sistem Ayarları",
        yetki: this.$yetki.enAzBiriVarMi([
          "personelleriListele ",
          "kimlikleriListele",
        ]),
        items: [
          {
            title: "Kurumlar",
            icon: "mdi-city-variant-outline",
            to: { name: "KurumlarList" },
            yetki: this.$yetki.varMi("kurumlariListele"),
          },
          {
            title: "Şubeler",
            icon: "mdi-city-variant-outline",
            to: { name: "SubelerList" },
            yetki: this.$yetki.varMi("subeleriListele"),
          },
          {
            title: "Dönemler",
            icon: "mdi-calendar-range",
            to: { name: "DonemlerList" },
            yetki: this.$yetki.varMi("donemleriListele"),
          },
          {
            title: "Personel İşlemleri",
            icon: "mdi-account-multiple-plus-outline",
            to: { name: "PersonelList" },
            yetki: this.$yetki.varMi("personelleriListele"),
          },
          {
            title: "Kimlikler",
            icon: "mdi-card-account-details-outline",
            to: { name: "KimliklerList" },
            yetki: this.$yetki.varMi("kimlikleriListele"),
          },
        ],
      },
    ];
  },

  data: () => ({
    kullanici_adi: null,
    list: [],
  }),
};
</script>

<style scoped>
.pl-64 {
  padding-left: 64px;
}
</style>
