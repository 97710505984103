<template>
  <layout>
    <template slot="main">
      <v-container class="d-flex justify-center">
        <span>{{ ad_soyad }}</span>
      </v-container>
      <v-col v-for="(item, index) in this.tum_dersler?.sonuc" :key="item.id">
        <Sparkline
          :gelen_data="gidecekData(index)"
          :gelen_value="gidecekValue(index)"
          :gelen_title="gidecekTitle(index)"
          v-if="$yetki.kullaniciTuru() === 'ÖĞRENCİ' && gidecekData(index)"
        ></Sparkline>
      </v-col>
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
import Sparkline from "@/components/sparkline/Sparkline";
import { ogrencinin_net_sayilarini_getir } from "@/query/olc_sinavlarim";

export default {
  components: { Layout, Sparkline },
  async mounted() {
    if (this.$yetki.kullaniciTuru() === "ÖĞRENCİ") {
      this.tum_dersler = await ogrencinin_net_sayilarini_getir(
        localStorage.getItem("ogrenci_id")
      );
      if (this.tum_dersler?.sonuc) {
        this.gidecek_data = this.tum_dersler?.sonuc?.reduce(
          (accumulator, ders) => {
            const sinavlar = ders.sinavlaraGoreNetSayilari.map(
              (sinav) => sinav.sinavToplamNetSayisi
            );
            return [...accumulator, ...sinavlar];
          },
          []
        );
        this.gidecek_value = this.gidecek_data.map((number) => {
          return Number(number.toFixed(2));
        });
        this.gidecek_title = this.tum_dersler.sonuc[0]?.sinavTuruKisaAdi;
      }

      this.ad_soyad = localStorage.getItem("ogrenci_ad_soyad");
    }
  },

  methods: {
    gidecekData(index) {
      return this.tum_dersler?.sonuc
        ? this.tum_dersler.sonuc[index]?.sinavlaraGoreNetSayilari.map(
            (sinav) => sinav.sinavToplamNetSayisi
          ) || []
        : [];
    },
    gidecekValue(index) {
      return this.gidecekData(index).map((number) => Number(number.toFixed(2)));
    },
    gidecekTitle(index) {
      return this.tum_dersler?.sonuc
        ? this.tum_dersler.sonuc[index]?.sinavTuruKisaAdi || null
        : null;
    },
  },

  data() {
    return {
      ad_soyad: null,
      gidecek_data: null,
      gidecek_value: null,
      gidecek_title: null,
      tum_dersler: null,
    };
  },
};
</script>
