var render = function render(){
  var _this$tum_dersler;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout', [_c('template', {
    slot: "main"
  }, [_c('v-container', {
    staticClass: "d-flex justify-center"
  }, [_c('span', [_vm._v(_vm._s(_vm.ad_soyad))])]), _vm._l((_this$tum_dersler = this.tum_dersler) === null || _this$tum_dersler === void 0 ? void 0 : _this$tum_dersler.sonuc, function (item, index) {
    return _c('v-col', {
      key: item.id
    }, [_vm.$yetki.kullaniciTuru() === 'ÖĞRENCİ' && _vm.gidecekData(index) ? _c('Sparkline', {
      attrs: {
        "gelen_data": _vm.gidecekData(index),
        "gelen_value": _vm.gidecekValue(index),
        "gelen_title": _vm.gidecekTitle(index)
      }
    }) : _vm._e()], 1);
  })], 2)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }