const varMi = (izin) => {
  const donemIzinleri = localStorage.getItem("donem_izinleri");
  if (!donemIzinleri) {
    return false;
  }
  const izinler = JSON.parse(donemIzinleri);
  return izinler.includes(izin);
};
const rolleriAl = () => JSON.parse(localStorage.getItem("roller"));
const yetki = {
  varMi,
  enAzBiriVarMi: (izinler) => izinler?.some(varMi),
  hepsiVarMi: (izinler) => izinler?.every(varMi),
  kullaniciTuru: () => {
    const roller = rolleriAl();
    if (roller?.includes("ÖĞRENCİ")) {
      return "ÖĞRENCİ";
    } else if (roller?.includes("ÖĞRETMEN")) {
      return "ÖĞRETMEN";
    }
    return null;
  },
};

module.exports = {
  yetki,
};
