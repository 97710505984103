import { ApiClient } from "../helpers/api-client";

const apiClient = new ApiClient();

const kullanici_listesi = async (filtre) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/api/v1/kullanicilariListele`,
    headers: {
      "donem-id": localStorage.getItem("donem_id"),
      jeton: localStorage.getItem("jeton"),
    },
    json: {
      kullanicilar: filtre,
    },
    method: "POST",
  });
};

const kullanici_getir = async (id) => {
  const response = await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/api/v1/kullaniciyiGetir`,
    headers: {
      "donem-id": localStorage.getItem("donem_id"),
      jeton: localStorage.getItem("jeton"),
    },
    json: {
      kullanici: { id },
    },
  });
  return response.veriler.sonuc.kullanici;
};

const kullanici_kaydet = async (
  kullaniciAdi,
  parola,
  aciklama,
  profilResmiYolu,
  yetkiler
) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/api/v1/kullaniciOlustur`,
    headers: {
      "donem-id": localStorage.getItem("donem_id"),
      jeton: localStorage.getItem("jeton"),
    },
    json: {
      kullanici: {
        kullaniciAdi,
        parola,
        aciklama,
        profilResmiYolu,
        yetkiler,
      },
    },
  });
};

const kullanici_guncelle = async (
  kullaniciAdi,
  parola,
  aciklama,
  profilResmiYolu,
  yetkiler,
  id,
  versiyon
) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/api/v1/kullaniciyiGuncelle`,
    headers: {
      "donem-id": localStorage.getItem("donem_id"),
      jeton: localStorage.getItem("jeton"),
    },
    json: {
      kullanici: {
        kullaniciAdi,
        parola,
        aciklama,
        profilResmiYolu,
        yetkiler,
        id: id,
        versiyon: versiyon,
      },
    },
  });
};
const silv2 = async (id, versiyon) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/api/v1/kullaniciyiGuncelle`,
    headers: {
      "donem-id": localStorage.getItem("donem_id"),
      jeton: localStorage.getItem("jeton"),
    },
    json: {
      kullanici: {
        durum: "arsivde",
        id: id,
        versiyon: versiyon,
      },
    },
  });
};
const kullanici_sil = async (id, versiyon) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/api/v1/kullaniciyiSil`,
    headers: {
      "donem-id": localStorage.getItem("donem_id"),
      jeton: localStorage.getItem("jeton"),
    },
    json: {
      kullanici: {
        id: id,
        versiyon: versiyon,
      },
    },
  });
};

const kullanici_donem_izinleri = async (donemId) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/api/v1/kullaniciDonemIzinleriniGetir`,
    headers: {
      "donem-id": localStorage.getItem("donem_id"),
      jeton: localStorage.getItem("jeton"),
    },
    json: {
      kullaniciDonemIzinleri: {
        donemId,
      },
    },
  });
};

export {
  kullanici_listesi,
  kullanici_getir,
  kullanici_kaydet,
  kullanici_guncelle,
  kullanici_sil,
  kullanici_donem_izinleri,
  silv2,
};
