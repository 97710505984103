<template>
  <div class="text-center">
    <v-dialog v-model="internalDialog" persistent width="500">
      <v-card>
        <v-card-title class="justify-center">
          <v-progress-circular
            v-if="beklet"
            :size="70"
            :width="7"
            color="blue"
            indeterminate
          ></v-progress-circular>
          <v-img
            v-if="beklet === false"
            max-width="100"
            :src="`./gizmo/${iconlar[internalIcon]}`"
          ></v-img>
        </v-card-title>

        <v-card-title class="justify-center display-1">
          {{ baslik }}
        </v-card-title>
        <v-card-text>
          <template v-if="liste">
            <div
              class="ml-13 subtitle-2"
              v-for="satir in internalAciklama.split('\n')"
              :key="satir"
            >
              {{ satir }}
            </div>
          </template>
          <template v-else>
            <div class="text-center title">
              {{ internalAciklama }}
            </div>
          </template>
        </v-card-text>

        <v-divider v-if="beklet === false"></v-divider>

        <v-card-actions class="justify-center">
          <v-btn v-if="evet" class="my-3" color="primary" @click="tamam">
            {{ buttonText }}
          </v-btn>
          <v-btn v-if="hayir" color="red" dark @click="vazgec"> Hayır </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: true,
    },
    icon: null,
    baslik: null,

    aciklama: {
      type: String,
      default: "",
    },
    liste: {
      type: Boolean,
      default: false,
    },
    hayir: {
      type: Boolean,
      default: true,
    },
    evet: {
      type: Boolean,
      default: true,
    },
    beklet: {
      type: Boolean,
      default: false,
    },
    evetCb: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      iconlar: {
        dikkat: "warning.png",
        hata: "error.png",
        bilgi: "info.png",
        basarili: "ok.png",
        soru: "question.png",
      },
      internalDialog: null,
      internalIcon: null,
      internalAciklama: this.aciklama,
      buttonText: "Evet",
    };
  },
  mounted() {
    this.internalDialog = this.dialog;
    this.internalIcon = this.icon;
    if (this.hayir === false && this.evet === true) {
      this.buttonText = "Tamam";
    }
  },
  methods: {
    async tamam() {
      if (this.beklet === false) {
        this.internalDialog = false;
      }
      await this.evetCb();
    },
    vazgec() {
      this.internalDialog = false;
    },
  },
};
</script>

<style></style>
