var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-speed-dial', {
    staticClass: "fixed-button",
    attrs: {
      "bottom": _vm.bottom,
      "direction": _vm.direction,
      "transition": _vm.transition
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "color": "blue darken-2",
            "dark": "",
            "fab": ""
          },
          model: {
            value: _vm.fab,
            callback: function ($$v) {
              _vm.fab = $$v;
            },
            expression: "fab"
          }
        }, [_vm.fab ? _c('v-icon', [_vm._v(" mdi-close ")]) : _c('v-icon', [_vm._v(" mdi-email-outline ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('v-btn', {
    attrs: {
      "fab": "",
      "dark": "",
      "small": "",
      "color": "green"
    },
    on: {
      "click": _vm.openWhatsApp
    }
  }, [_c('v-icon', [_vm._v("mdi-whatsapp")])], 1), _c('v-btn', {
    attrs: {
      "fab": "",
      "dark": "",
      "small": "",
      "color": "pink"
    },
    on: {
      "click": _vm.openInstagram
    }
  }, [_c('v-icon', [_vm._v("mdi-instagram")])], 1), _c('v-btn', {
    attrs: {
      "fab": "",
      "dark": "",
      "small": "",
      "color": "red"
    },
    on: {
      "click": _vm.openYoutube
    }
  }, [_c('v-icon', [_vm._v("mdi-youtube")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }