var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-footer', {
    attrs: {
      "color": "ma-1 primary lighten-1",
      "app": "",
      "fixed": "",
      "padless": ""
    }
  }, [_c('BirlestirilmisKonuAnalizSecenegi', {
    attrs: {
      "modal": _vm.birlestirilmisKonuAnaliziTetikle
    },
    on: {
      "hideDialog": function (i, s) {
        return _vm.BirlestirilmisKonuShowDialog(i, s);
      }
    }
  }), _c('v-row', {
    attrs: {
      "justify": "center",
      "no-gutters": ""
    }
  }, _vm._l(_vm.icons, function (icon, index) {
    return _c('v-btn', {
      key: icon,
      staticClass: "my-2",
      attrs: {
        "to": _vm.to[index],
        "color": "white",
        "text": "",
        "rounded": ""
      },
      on: {
        "click": function ($event) {
          return _vm.analizIndir(_vm.text[index]);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex flex-column align-center"
    }, [_c('v-icon', {
      attrs: {
        "size": "20px"
      }
    }, [_vm._v(_vm._s(icon))]), _c('span', {
      staticClass: "mt-1 text-caption text-center"
    }, [_vm._v(_vm._s(_vm.text[index]))])], 1)]);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }