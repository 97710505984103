<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {};
</script>
<style>
.v-main {
  margin-bottom: 85px !important;
}
</style>
