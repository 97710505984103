<template>
  <v-container>
    <v-card class="mt-8 mx-auto">
      <v-sheet
        class="v-sheet--offset mx-auto"
        color="cyan"
        elevation="12"
        max-width="calc(100% - 32px)"
      >
        <v-sparkline
          :labels="labels"
          :show-labels="true"
          :value="value"
          :gradient="gradient"
          :smooth="radius || false"
          :padding="padding"
          :line-width="width"
          :stroke-linecap="lineCap"
          :gradient-direction="gradientDirection"
          :fill="fill"
          :type="type"
          :auto-line-width="autoLineWidth"
          auto-draw
        ></v-sparkline>
      </v-sheet>

      <v-card-text class="pt-0">
        <div class="text-h6 font-weight-light mb-2">{{ title }} Netleri</div>
        Toplam girilen sınav sayısı <strong>{{ gelen_data.length }}</strong>
        <!-- <div class="subheading font-weight-light grey--text">Ortalaalar</div> -->
        <v-divider class="my-2"></v-divider>
        <!-- <v-icon class="mr-2" small> mdi-clock </v-icon>
        <span class="text-caption grey--text font-weight-light"
          >Son girdiği sınav tarihi</span
        > -->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
const gradients = [
  ["#222"],
  ["#42b3f4"],
  ["red", "orange", "yellow"],
  ["purple", "violet"],
  ["#00c6ff", "#F0F", "#FF0"],
  ["#f72047", "#ffd200", "#1feaea"],
];

export default {
  props: { gelen_data: null, gelen_value: null, gelen_title: null },
  components: {},
  mounted() {
    this.value = this.gelen_data;
    this.labels = this.gelen_value;
  },
  // mounted() {
  //   if (JSON.parse(localStorage.getItem("roller"))?.includes("ÖĞRENCİ")) {
  //     this.$router.push({ name: "SinavlarimList" }).catch(() => {});
  //   }
  //   if (JSON.parse(localStorage.getItem("roller"))?.includes("ÖĞRETMEN")) {
  //     this.$router.push({ name: "OgrencilerimList" }).catch(() => {});
  //   }
  // },

  data() {
    return {
      width: 2,
      radius: 10,
      padding: 8,
      lineCap: "round",
      gradient: gradients[5],
      value: this.gelen_data,
      labels: this.gelen_data,
      gradientDirection: "top",
      gradients,
      fill: false,
      type: "trend",
      autoLineWidth: false,
      title: this.gelen_title,
    };
  },
};
</script>
<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>
