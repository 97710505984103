var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [[_vm.narBar ? _vm._t("nav-bar", function () {
    return [_c('nav-bar')];
  }) : _vm._e(), _c('v-main', [_vm._t("app-bar", function () {
    return [_c('app-bar')];
  }), _vm._t("main"), _vm._t("menu-button", function () {
    return [_c('menu-button')];
  })], 2), _vm.footer ? _vm._t("footer-bar", function () {
    return [_c('footer-bar')];
  }) : _vm._e(), _vm._v(" -- ")]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }