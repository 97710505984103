import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VuetifyDialog from "vuetify-dialog";
import "vuetify-dialog/dist/vuetify-dialog.css";
import VueTheMask from "vue-the-mask";
import VueApexCharts from "vue-apexcharts";
import { VueMasonryPlugin } from "vue-masonry";
import { yetki } from "./helpers/yetkiler";
import GizmoAlert from "./components/alert/GizmoAlert.vue";

Vue.config.productionTip = false;
Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
});

Vue.use(VueTheMask);
Vue.use(require("vue-moment"));
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
Vue.component("gizmo-alert", GizmoAlert);

Vue.prototype.$gizmo = function () {
  let gizmoAlert = null;

  const ac = ({
    baslik,
    icon,
    aciklama,
    hayir,
    evet,
    evetCb,
    beklet,
    liste,
  }) => {
    gizmoAlert = new Vue({
      vuetify,
      render: (h) =>
        h(GizmoAlert, {
          props: { baslik, icon, aciklama, hayir, evet, evetCb, beklet, liste },
        }),
    }).$mount();
    document.body.appendChild(gizmoAlert.$el);
    return gizmoAlert;
  };

  const kapat = (gz) => {
    if (gz) {
      document.body.removeChild(gz.$el);
      gz.$destroy();
      gz = null;
    }
  };

  const beklet = () => {
    return ac({
      aciklama: "Yükleniyor...",
      icon: "bilgi",
      beklet: true,
      evet: false,
      hayir: false,
    });
  };

  return {
    kapat,
    ac,
    beklet,
  };
};

Vue.prototype.$yetki = yetki;
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

Vue.use(VueMasonryPlugin);
