var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-navigation-drawer', {
    attrs: {
      "mini-variant": _vm.$store.state.navDrawer,
      "permanent": "",
      "app": ""
    },
    on: {
      "update:miniVariant": function ($event) {
        return _vm.$set(_vm.$store.state, "navDrawer", $event);
      },
      "update:mini-variant": function ($event) {
        return _vm.$set(_vm.$store.state, "navDrawer", $event);
      }
    },
    model: {
      value: _vm.$store.state.navDrawer,
      callback: function ($$v) {
        _vm.$set(_vm.$store.state, "navDrawer", $$v);
      },
      expression: "$store.state.navDrawer"
    }
  }, [_c('v-list', [_c('v-list-item', {
    staticClass: "justify-center"
  }, [_c('v-img', {
    attrs: {
      "max-height": _vm.$store.state.navDrawer ? 50 : 80,
      "max-width": _vm.$store.state.navDrawer ? 50 : 80,
      "src": require("@/assets/netkod.png")
    }
  })], 1), _c('v-list-item', [_c('v-list-item-content', [!_vm.$store.state.navDrawer ? _c('v-list-item-title', {
    staticClass: "text-h6"
  }, [_vm._v(_vm._s(_vm.kullanici_adi))]) : _vm._e()], 1), _c('v-list-item-action', [_c('v-icon', [_vm._v("mdi-menu-down")])], 1)], 1)], 1), _c('v-divider'), _c('v-list', {
    attrs: {
      "nav": "",
      "dense": ""
    }
  }, _vm._l(_vm.list, function (_ref, i) {
    var listGroup = _ref.listGroup,
      prependIcon = _ref.prependIcon,
      items = _ref.items,
      value = _ref.value,
      title = _ref.title,
      icon = _ref.icon,
      to = _ref.to,
      yetki = _ref.yetki;
    return _c('div', {
      key: i
    }, [listGroup ? _c('div', [yetki ? _c('v-list-group', {
      attrs: {
        "value": value,
        "prepend-icon": prependIcon,
        "no-action": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function () {
          return [_c('v-list-item-title', [_vm._v(_vm._s(title))])];
        },
        proxy: true
      }], null, true)
    }, _vm._l(items, function (_ref2, i) {
      var subGroup = _ref2.subGroup,
        subItems = _ref2.subItems,
        value = _ref2.value,
        title = _ref2.title,
        icon = _ref2.icon,
        to = _ref2.to,
        yetki = _ref2.yetki;
      return _c('div', {
        key: i
      }, [subGroup ? _c('div', [yetki ? _c('v-list-group', {
        attrs: {
          "value": value,
          "no-action": "",
          "sub-group": ""
        },
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function () {
            return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(title))])], 1)];
          },
          proxy: true
        }], null, true)
      }, _vm._l(subItems.filter(function (v) {
        return v.yetki === yetki;
      }), function (_ref3, i) {
        var title = _ref3.title,
          icon = _ref3.icon,
          to = _ref3.to;
        return _c('v-list-item', {
          key: i,
          attrs: {
            "to": to,
            "link": ""
          }
        }, [_c('v-list-item-title', [_vm._v(_vm._s(title))]), _c('v-list-item-icon', [_c('v-icon', [_vm._v(" " + _vm._s(icon))])], 1)], 1);
      }), 1) : _vm._e()], 1) : _c('div', [yetki ? _c('v-list-item', {
        key: i,
        staticClass: "pl-64",
        attrs: {
          "to": to
        }
      }, [_c('v-list-item-title', [_vm._v(_vm._s(title))]), _c('v-list-item-icon', [_c('v-icon', [_vm._v(" " + _vm._s(icon))])], 1)], 1) : _vm._e()], 1)]);
    }), 0) : _vm._e()], 1) : _c('div', [yetki ? _c('v-list-item', {
      attrs: {
        "to": to
      }
    }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v(" " + _vm._s(icon))])], 1), _c('v-list-item-title', [_vm._v(_vm._s(title))])], 1) : _vm._e()], 1)]);
  }), 0)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }